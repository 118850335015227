import { Card, Col } from 'react-bootstrap';

export const Service = ({ title, img, description, imagen }) => {
  return (
    <Col
      md={6}
      lg={4}
      className="wow fadeInUp services_container"
      data-wow-delay="0.1s"
    >
      <Card
        className={` services service-item d-block rounded text-center ${
          !imagen ? 'p-4 ' : ''
        }`}
      >
        {!imagen ? (
          <>
            <img className="img-fluid rounded mb-4" src={img} alt={title} />
            <h4 className="mb-0" style={{ fontSize: '1.2em' }}>
              {title}
            </h4>
            <div className="services__info  cardInfo">
              <img className="img-fluid rounded mb-4" src={img} alt={title} />
              <h4>{title}</h4>
              <p>{description}</p>
            </div>{' '}
          </>
        ) : (
          <img
            style={{ width: '80%', height: '80%' }}
            className=""
            src={img}
            alt={title}
          />
        )}
      </Card>
    </Col>
  );
};
