import { Text, View, StyleSheet } from "@react-pdf/renderer";
const styles = StyleSheet.create({
  container: {
    alignSelf: "flex-start",
    marginLeft: "30px",
    marginTop: "30px",
  },
  font: {
    fontSize: "8px",
  },
});
const ContratoPag = () => (
  <View style={styles.container}>
    <Text style={styles.font}>Página: 1 de 1</Text>
  </View>
);
export default ContratoPag;
