import axios from '../api/axios';
import actualizarTokenNoLog from './actualizarTokenNoLog';

export const postResumen = async (
  setTokenCambiar,
  setTokenVencido,
  setToken,
  setResumen
) => {
  let login = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));
  let plan = JSON.parse(localStorage.getItem('RCV_DIGITAL_PLAN'));
  let clase = JSON.parse(localStorage.getItem(`RCV_DIGITAL_CLASE`));
  let clientId = JSON.parse(localStorage.getItem('CLIENT_ID'));
  let idVehiculo = JSON.parse(localStorage.getItem('VEHICULO_ID'));
  let idUsuDescuento = JSON.parse(
    localStorage.getItem('RCV_DIGITAL_USDESCUENTO')
  );
  let carrito = JSON.parse(localStorage.getItem('RCV_DIGITAL_CARRITO'));

  let token = JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN'));
  let proArray = [];
  if (carrito !== undefined) {
    carrito?.forEach(item => {
      proArray.push({
        id_pro: item.id_pro,
        ct_pro: item.cantidad,
      });
    });
  }
  let json = JSON.stringify({
    resumen: {
      id_pq: plan.id_pq,
      id_cl_vh: clase.id_cl_vh,
      id_cl: clientId,
      id_vh: idVehiculo,
      id_us_as: idUsuDescuento === undefined ? null : idUsuDescuento,
      cd_prom: '',
      pro: carrito === undefined ? null : proArray,
    },
    trace: '0',
    token: token,
  });

  console.log(json);

  const handleError = async error => {
    if (error.response && error.response.status === 403) {
      if (login === undefined || login === null) {
        actualizarTokenNoLog(setToken);
      } else {
        setTokenCambiar(true);
        setTokenVencido(error.response.data.token);
        console.log(error.response.data.token);
      }
    } else {
      console.log(error);
    }
  };

  try {
    const data = await axios.post(process.env.REACT_APP_RESUMEN, json);
    localStorage.setItem('RCV_DIGITAL_TOKEN', JSON.stringify(data.data.token));
    localStorage.setItem('TOTAL_PAGO', JSON.stringify(data.data.data.bill));
    setResumen(data.data.data);
  } catch (error) {
    handleError(error);
  }
};
