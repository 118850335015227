import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import ResumenDeVentasTotalesComision from './ResumenDeVentasTotalesComision';
import ResumenTotalGeneralComision from './ResumenTotalGeneralComision';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  header: {
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
  },
  headerFuente: {
    fontFamily: 'Arial',
    borderStyle: 'solid',
    textAlign: 'center',
    fontWeight: 'bold',
    fontStyle: 'bold',
    fontSize: 8,
  },
  footer: {
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 5,
  },
  footerFuente: {
    fontFamily: 'Arial',
    borderStyle: 'solid',
    textAlign: 'center',
    fontWeight: 'bold',
    fontStyle: 'bold',
    fontSize: 8,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
  },
  tableColHeader: {
    fontFamily: 'Arial',
    width: '100%',
    height: '100%',
    borderStyle: 'solid',
    borderRightWidth: 1,
    textAlign: 'center',
    fontWeight: 'bold',
    fontStyle: 'bold',
    fontSize: 7,
    padding: 4,
    paddingTop: '6px',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: ' center',
  },
  tableCol: {
    width: '100%',
    borderStyle: 'solid',
    borderRightWidth: 1,
    textAlign: 'start',
    fontSize: 7,
    padding: 5,
  },
  tableColCant: {
    width: '100%',
    borderStyle: 'solid',
    borderRightWidth: 1,
    textAlign: 'center',
    fontSize: 7,
    padding: 5,
  },
  tableColMontos: {
    width: '100%',
    borderStyle: 'solid',
    borderRightWidth: 1,
    textAlign: 'right',
    fontSize: 7,
    padding: 5,
  },
});

const ResumenVentaComision = ({ reporte }) => {
  const porcentaje = reporte?.VentasCommission[0]?.pc_cm_ag;
  return (
    <View>
      <View style={styles.header}>
        <Text style={styles.headerFuente}>RESUMEN</Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <Text style={[styles.tableColHeader, { width: '30%' }]}>AGENTE</Text>
          <Text style={[styles.tableColHeader, { width: '10%' }]}>
            EXENTO
          </Text>{' '}
          <Text style={[styles.tableColHeader, { width: '10%' }]}>BASE</Text>
          <Text style={[styles.tableColHeader, { width: '10%' }]}>IVA</Text>
          <Text style={[styles.tableColHeader, { width: '10%' }]}>I.G.T.F</Text>
          <Text style={[styles.tableColHeader, { width: '10%' }]}>
            DESCUENTO
          </Text>
          <Text style={[styles.tableColHeader, { width: '10%' }]}>INGRESO</Text>
          <Text style={[styles.tableColHeader, { width: '10%' }]}>
            BASE COMISI&Oacute;N
          </Text>
          <Text style={[styles.tableColHeader, { width: '10%' }]}>
            COMISI&Oacute;N {porcentaje}%
          </Text>
          <Text style={[styles.tableColHeader, { width: '10%' }]}>
            TOTAL REFERENCIAL
          </Text>
        </View>
        {reporte?.ResumenVentasCommission?.map((row, index) => {
          let totalReferencial =
            row.sm_mt_ge_tt / reporte?.VentasCommission[0]?.tasa;
          const baseComision =
            row?.sm_mt_ba_tt + row?.sm_mt_ex_tt - row?.sm_mt_ds_tt;
          return (
            <View key={index} style={styles.tableRow}>
              <Text style={[styles.tableCol, { width: '30%' }]}>
                {row.nb_agente} ({row.us_agente})
              </Text>

              <Text style={[styles.tableColMontos, { width: '10%' }]}>
                {row.sm_mt_ex_tt.toLocaleString('es-VE', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
              <Text style={[styles.tableColMontos, { width: '10%' }]}>
                {row.sm_mt_ba_tt.toLocaleString('es-VE', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
              <Text style={[styles.tableColMontos, { width: '10%' }]}>
                {row.sm_mt_iv_tt.toLocaleString('es-VE', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
              <Text style={[styles.tableColMontos, { width: '10%' }]}>
                {row.sm_mt_igtf}
              </Text>
              <Text style={[styles.tableColMontos, { width: '10%' }]}>
                {row.sm_mt_ds_tt.toLocaleString('es-VE', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
              <Text style={[styles.tableColMontos, { width: '10%' }]}>
                {row.sm_mt_ge_tt.toLocaleString('es-VE', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
              <Text style={[styles.tableColMontos, { width: '10%' }]}>
                {baseComision.toLocaleString('es-VE', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
              <Text style={[styles.tableColMontos, { width: '10%' }]}>
                {row.sm_mt_cm_ag.toLocaleString('es-VE', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>

              <Text style={[styles.tableColMontos, { width: '10%' }]}>
                {totalReferencial.toLocaleString('es-VE', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
            </View>
          );
        })}
        <ResumenDeVentasTotalesComision reporte={reporte} />
      </View>
      <ResumenTotalGeneralComision reporte={reporte} />
    </View>
  );
};

export default ResumenVentaComision;
