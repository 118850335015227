import React, { useState, useEffect, useContext } from 'react';
import PDFContratoRenderizador from './PDFContratoRenderizador';
import axios from 'axios';
import { getToken } from '../../../helpers/getToken';
import { TokenContext } from '../../../context/TokenContext';
import { LoginContext } from '../../../context/LoginContext';
import { format } from 'date-fns';
import { QRCodeCanvas } from 'qrcode.react';
import { es } from 'date-fns/locale';
import conseguirValor from '../helper/conseguirValor';
import { useParams } from 'react-router-dom';

const PDFContratoVista = () => {
  const { id_co } = useParams();
  const [contratoData, setContratoData] = useState({ detalle: [] });
  const [isLoading, setIsLoading] = useState(true);
  const { setNavbar } = useContext(LoginContext);
  const [currentDate] = useState(new Date());
  const [imagenqr, setImagenqr] = useState();
  const [imgContratoQR, setImgContratoQR] = useState();
  const [valorSup, setValorSup] = useState();
  const [valorClienteQR, setValorClienteQR] = useState();
  let login = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));
  const { setTokenCambiar, setTokenVencido } = useContext(TokenContext);
  const token = JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN'));
  const [tiempo, setTiempo] = useState({
    hora: '',
    fecha: '',
  });

  const fetchData = async prop => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_API}v1/getReport?p1=${prop}&p2=&p3=&p4=&op=1&token=${token}`
      );
      setContratoData(response.data.data[0] || { detalle: [] });
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 403) {
        if (!login) {
          try {
            const tokenAct = await getToken();
            localStorage.setItem('RCV_DIGITAL_TOKEN', JSON.stringify(tokenAct));
            const retryResponse = await axios.get(
              `${process.env.REACT_APP_URL_API}v1/getReport?p1=${prop}&p2=&p3=&p4=&op=1&token=${tokenAct}`
            );
            console.log('Retry API Response:', retryResponse.data);
            setContratoData(retryResponse.data.data[0] || { detalle: [] });
          } catch (retryError) {
            console.error('Error retrying with new token:', retryError);
          }
        } else {
          setTokenCambiar(true);
          setTokenVencido(error.response.data.token);
          console.log('Token expired, new token:', error.response.data.token);
        }
      }
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };
  useEffect(() => {
    setNavbar(false);
    if (!id_co) {
      const storedState = localStorage.getItem('linkStateContrato');
      if (storedState) {
        let storeParse = JSON.parse(storedState);

        fetchData(storeParse.id_co);
        localStorage.removeItem('linkStateContrato');
      }
    } else {
      fetchData(id_co);
      localStorage.removeItem('linkStateContrato');
    }
  }, []);

  useEffect(() => {
    const fechaFormateada = format(currentDate, 'dd/MM/yyyy', { locale: es });
    const horaFormateada = format(currentDate, 'hh:mm:ss a', { locale: es });
    setTiempo({
      hora: horaFormateada,
      fecha: fechaFormateada,
    });
  }, [currentDate]);

  const planA = contratoData.detalle.filter(
    elemento => elemento.cd_tp_bn === '0001'
  );
  const planB = contratoData.detalle.filter(
    elemento => elemento.cd_tp_bn === '0002'
  );
  const planC = contratoData.detalle.filter(
    elemento => elemento.cd_tp_bn === '0003'
  );

  const sumaA = planA.reduce(
    (acumulador, elemento) => acumulador + elemento?.mt_pq_dt,
    0
  );
  const sumaB = planB.reduce(
    (acumulador, elemento) => acumulador + elemento?.mt_pq_dt,
    0
  );
  const sumaC = planC.reduce(
    (acumulador, elemento) => acumulador + elemento?.mt_pq_dt,
    0
  );

  const sumaTotal = sumaA + sumaB + sumaC;

  const [datosA, setDatosA] = useState();
  const [datosB, setDatosB] = useState();
  const [datosC, setDatosC] = useState();

  useEffect(() => {
    try {
      setDatosA({ cod: planA?.[0]?.cd_tp_bn, nombre: planA?.[0]?.ds_tp_bn });
      setDatosB({ cod: planB?.[0]?.cd_tp_bn, nombre: planB?.[0]?.ds_tp_bn });
      setDatosC({ cod: planC?.[0]?.cd_tp_bn, nombre: planC?.[0]?.ds_tp_bn });
    } catch (error) {}
  }, [contratoData]);

  useEffect(() => {
    conseguirValor(
      contratoData,
      setValorSup,
      setValorClienteQR,
      setImgContratoQR,
      setImagenqr
    );
  }, [contratoData]);

  return (
    <div className="contratoMobile">
      <QRCodeCanvas
        value={valorSup}
        size={256}
        fgColor="#000000"
        bgColor="transparent"
        level="L"
        includeMargin={true}
        id="seguridadQR"
        style={{ display: 'none' }}
      />
      <QRCodeCanvas
        value={valorClienteQR}
        size={256}
        fgColor="#000000"
        bgColor="transparent"
        level="L"
        includeMargin={true}
        id="clienteQR"
        style={{ display: 'none' }}
      />
      {isLoading ? (
        <div className="contratoMobile__header">
          <span>CARGANDO CONTRATO...</span>
          <div className="lds-ellipsisg">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <>
          <PDFContratoRenderizador
            contratoData={contratoData}
            tiempo={tiempo}
            planA={planA}
            planB={planB}
            planC={planC}
            sumaA={sumaA}
            sumaB={sumaB}
            sumaC={sumaC}
            datosA={datosA}
            datosB={datosB}
            datosC={datosC}
            sumaTotal={sumaTotal}
            imagenqr={imagenqr}
            imgContratoQR={imgContratoQR}
          />
        </>
      )}
    </div>
  );
};

export default PDFContratoVista;
