import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    width: '480px',
    alignSelf: 'flex-start',
    marginLeft: '30px',
    marginTop: '20px',
  },
  font: {
    fontFamily: 'Arial',
    fontStyle: 'bold',
    fontSize: '6px',
  },
});
const ContratoFooterInfo = () => (
  <div style={styles.container}>
    <View>
      <Text style={styles.font}>
        EN CASO DE CHOQUE: COMUNIQUESE POR LOS Nº.: 0412-6881664 / 0424-6243038
        / 0261-7193619 DE LUN. A VIE. DE 8:00 a.m. - 5:00 p.m. Y POR:
        0424-6382763. LOS FINES DE SEMANA Y NOCTURNO DE 5:01 p.m. A 7:59 a.m.
      </Text>
    </View>
  </div>
);
export default ContratoFooterInfo;
