import { useState, createContext, useEffect, useContext } from 'react';
import Navbar from '../components/Navbar/Navbar';
import consultarApi from '../helpers/consultarApi';
import { TokenContext } from './TokenContext';
export const LoginContext = createContext(undefined);

const LoginProvider = ({
  children,
  setPrivado,

  setTokenExp,
  setTokenController,

  tokenExp,
  tokenController,
}) => {
  const [loginStatus, setLoginStatus] = useState(false);
  const { setTokenCambiar, setTokenVencido, token, setToken, cancelar } =
    useContext(TokenContext);
  const contexto = useContext(TokenContext);
  const [tasa, setTasa] = useState();
  const [navbarErrores, setNavbarErrores] = useState([]);
  const [mostrarErrores, setMostrarErrores] = useState(false);
  const [codigoDescuentoMostrar, setCodigoDescuentoMostrar] = useState(false);
  const [log, setLog] = useState(
    JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'))
  );
  const [navbar, setNavbar] = useState(true);

  useEffect(() => {
    consultarApi(
      process.env.REACT_APP_GET_TASA,
      0,
      setTasa,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    );
  }, [token]);

  return (
    <LoginContext.Provider
      value={{
        setLog,
        setNavbar,
        setNavbarErrores,
        setTokenController,
        setTokenExp,
        setMostrarErrores,
        setCodigoDescuentoMostrar,
        tasa,
      }}
    >
      {navbar ? (
        <Navbar
          setPrivado={setPrivado}
          login={log}
          setLogin={setLog}
          loginStatus={loginStatus}
          setLoginStatus={setLoginStatus}
          token={token}
          setTokenExp={setTokenExp}
          setTokenController={setTokenController}
          setToken={setToken}
          navbarErrores={navbarErrores}
          mostrarErrores={mostrarErrores}
          setMostrarErrores={setMostrarErrores}
          tokenExp={tokenExp}
          tokenController={tokenController}
          tasa={tasa}
        />
      ) : null}

      {children}
    </LoginContext.Provider>
  );
};

export default LoginProvider;
