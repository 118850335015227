import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import imageRCV from '../../styles/images/IMG_20230727_151123-_2_.webp';
import Consultar from '../Consultar/Consultar';

export const HeroIndex = ({ version }) => {
  const [cargando, setCargando] = useState(true);
  const [mostrarGenerar, setMostrarGenerar] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setCargando(false);
    }, 1000);
  }, []);
  return (
    <section className="heroindex">
      <Consultar
        mostrarGenerar={mostrarGenerar}
        setMostrarGenerar={setMostrarGenerar}
      />
      <div>
        <header className="heroindex__header">
          <h1>
            Adquiere tu <span>RCV</span>
          </h1>
        </header>

        <div className="heroindex__parrafo">
          <p>
            Aquí podrás adquirir tu rcv. Elige el mejor plan para ti y deja las
            preocupaciones.
          </p>
        </div>
        <div className="heroindex__buttonContainer">
          <div className="heroindex__buttonContainer-container">
            {' '}
            <Link to={cargando ? '#' : '/proceso/clases'}>
              <button className="heroindex__buttonContainer-button">
                {cargando ? (
                  <div className="lds-ellipsisg">
                    <div style={{ backgroundColor: 'white' }}></div>
                    <div style={{ backgroundColor: 'white' }}></div>
                    <div style={{ backgroundColor: 'white' }}></div>
                    <div style={{ backgroundColor: 'white' }}></div>
                  </div>
                ) : (
                  'COMPRAR'
                )}
              </button>
            </Link>
            <span className="heroindex__buttonContainer-container-version">
              {' '}
              Versi&oacute;n: {version}
            </span>
          </div>
          {/** 
            <button className="heroindex__buttonContainer-button">
              {cargando ? (
                <div className="lds-ellipsisg">
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                </div>
              ) : (
                'COMPRAR'
              )}
            </button>
            <span className="heroindex__buttonContainer-button-version">
              {' '}
              Versi&oacute;n: {version}
            </span>
          </Link>
       <button onClick={() => setMostrarGenerar(true)}>CONSULTAR</button> */}
        </div>
      </div>

      <div className="heroindex__banner"></div>
      <img
        src={imageRCV}
        alt="imagen-venezuela-responsable"
        loading="lazy"
        className="heroindex__banner-img"
      />
    </section>
  );
};
