import logoVr from '../../img/logo_n_ulimate.png';
import RecuperarContraseña from './RecuperarContraseña/RecuperarContraseña';

const Opcion = ({ opcion, setOpcion, mostrarOp }) => {
  const style = {
    display: 'flex',
    width: '100%',
  };
  return (
    <div
      style={mostrarOp ? style : null}
      className={`iniciarYRegistro__opcion ${opcion === 2 ? 'active' : ''} ${
        opcion === 3 ? 'activeMO' : ''
      }`}
    >
      <div
        className={`iniciarYRegistro__opcion-registrar ${
          opcion === 2 || opcion === 3 ? 'ocultar' : ''
        }`}
      >
        <h5>¿Eres nuevo aqu&iacute;?</h5>
        <span>Obt&eacute;n tu cuenta</span>
        <button
          onClick={() => {
            opcion === 1 ? setOpcion(2) : setOpcion(1);
          }}
        >
          REG&Iacute;STRATE
        </button>
      </div>
      <div
        className={`iniciarYRegistro__opcion-registrar ${
          opcion === 2 ? '' : 'ocultar'
        }`}
      >
        <img loading="lazy" alt="logo de Venezuela Responsable" src={logoVr} />
        <h4>
          Crea tu cuenta con nosotros y podrás gestionar tu RCV en cualquier
          momento
        </h4>
        <h5>¿Ya tienes una cuenta?</h5>

        <button
          onClick={() => {
            opcion === 2 ? setOpcion(1) : setOpcion(2);
          }}
        >
          INICIA SESI&Oacute;N
        </button>
        <span>Administra tus RCV's</span>
      </div>
    </div>
  );
};

export default Opcion;
