import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
  },
  titulo: {
    fontSize: 9,
    fontFamily: 'Arial',
    fontWeight: 'bold',
    fontStyle: 'bold',
  },
  valor: {
    fontSize: 9,
  },
});

const HeaderReportesComisiones = ({ reporte, userProfile, fecha, hora }) => (
  <View style={styles.header}>
    <View style={styles.left}>
      <View style={styles.item}>
        <Text style={styles.titulo}>OFICINA:</Text>{' '}
        <Text style={styles.valor}>RCV DIGITAL</Text>
      </View>
      <View style={styles.item}>
        <Text style={styles.titulo}>USUARIO:</Text>{' '}
        <Text style={styles.valor}>{`${
          userProfile?.nb_us + ' ' + userProfile?.ap_us
        }`}</Text>
      </View>
    </View>
    <View style={styles.right}>
      <View style={styles.item}>
        <Text style={styles.titulo}>SERIE:</Text>{' '}
        <Text style={styles.valor}>{reporte?.VentasCommission[0]?.serie}</Text>
      </View>
      <View style={styles.item}>
        <Text style={styles.titulo}>IMPRESO:</Text>{' '}
        <Text style={styles.valor}>
          {fecha} {hora}
        </Text>
      </View>
    </View>
  </View>
);
export default HeaderReportesComisiones;
