import React from 'react';
import { Link } from 'react-router-dom';
import LogoVR from '../../styles/images/Logo en png.png';

const SucursalesHero = () => {
  return (
    <div className="sucursaleshero wow fadeInUp">
      <Link to="/sucursales" className="sucursaleshero__link">
        <h6
          style={{ backgroundColor: '#e8e8e6' }}
          className="section-title text-center text-primary px-2"
        >
          Sucursales
        </h6>
        <h1 className="display-6 mb-4 fw-bold">
          Responsabilidad civil excepcional en todo el pa&iacute;s a los mejores
          precios
        </h1>
        <button className="sucursaleshero__link-button">VER SUCURSALES</button>
      </Link>
      <div className="sucursaleshero__imgContenedor">
        <img
          src={LogoVR}
          alt="mapa de venezuela"
          className="sucursaleshero__imgContenedor-venezuela "
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default SucursalesHero;
