import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactWhatsapp from 'react-whatsapp';
import { useState, useEffect } from 'react';
import imgCorreo from '../../styles/images/correo.png';

const Footer = () => {
  const [backToTopButton, setBackToTopButton] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        setBackToTopButton(true);
      } else {
        setBackToTopButton(false);
      }
    });
  }, []);

  const scrollUp = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <Container
      fluid
      className="text-secondary footer footerhome pt-5 "
      data-wow-delay="0.1s"
    >
      <Container className="py-5">
        <Row className="g-5">
          <Col md={6} lg={3}>
            <h1 style={{ fontSize: '1.3em' }} className="text-light mb-4">
              Dirección
            </h1>
            <p className="mb-2 text-light">
              <i className="fa text-light fa-map-marker-alt me-3"></i>Av. 8
              Santa Rita con esquina calle 86
            </p>
            <p className="mb-2 text-light">
              <i className="text-light fa fa-phone-alt me-3"></i>+58 412 5145856
            </p>
            <p className="mb-2 text-light">
              <i className="text-light fa fa-envelope me-3"></i>
              <img src={imgCorreo} alt="imagen del correo" height={'22px'} />
            </p>
            <div className="d-flex pt-2">
              <a
                className="btn btn-square btn-outline-light rounded-circle me-1"
                href="https://www.facebook.com/people/Venezuela-Responsable/100063717486873/?fref=ts"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                className="btn btn-square btn-outline-light rounded-circle me-1"
                href="https://www.instagram.com/venezuelaresponsable/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <ReactWhatsapp
                className="btn btn-square btn-outline-light rounded-circle me-1"
                number="58-412-514-5856"
                message="Hola Venezuela Responsable"
              >
                <i className="fab fa-whatsapp"></i>
              </ReactWhatsapp>
            </div>
          </Col>
          <Col md={6} lg={3}>
            <h5 className="text-light mb-4">Enlaces rápidos</h5>
            <Link
              className="btn btn-link text-light"
              style={{ textDecoration: 'none' }}
              to="#"
              onClick={scrollUp}
            >
              Inicio
            </Link>
            {/**   <Link
              className="btn btn-link text-light"
              style={{ textDecoration: 'none' }}
              to="/compra/rcv/clases"
            >
              Comprar
            </Link>
            */}
            <Link
              className="btn btn-link text-light"
              style={{ textDecoration: 'none' }}
              to="/terminos"
            >
              Términos y condiciones
            </Link>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
