import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    right: '120px',
    top: '105px',
  },
  font: {
    fontSize: '8px',
    fontFamily: 'Arial',
    fontStyle: 'bold',
  },
});
const ContratoVendedor = () => (
  <View style={styles.container}>
    <Text style={styles.font}>VENDEDOR: RAFAEL LEAL</Text>
  </View>
);
export default ContratoVendedor;
