import { Text, View, StyleSheet } from '@react-pdf/renderer';
const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '150px',
    right: '40px',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  containerSon: {
    display: 'flex',
    flexDirection: 'row',
  },
  font: {
    fontSize: '8px',
  },
  fontBold: {
    fontFamily: 'Arial',
    fontSize: '8px',
    fontStyle: 'bold',
  },
});
const ContratoDocumento = ({ contratoData }) => {
  const partes = contratoData?.cd_co?.split('-');
  const resultado =
    partes && partes.length >= 5 ? partes[0] + '-' + partes[4] : '';

  return (
    <div style={styles.container}>
      <div style={styles.containerSon}>
        <View>
          <Text style={styles.fontBold}>DOCUMENTO: </Text>
        </View>
        <View>
          <Text style={styles.font}>{resultado}</Text>
        </View>
      </div>
      <View>
        <Text style={styles.font}>Documento - (original)</Text>
      </View>
      <View>
        <Text style={styles.font}>8.0/8.2</Text>
      </View>
    </div>
  );
};
export default ContratoDocumento;
