import { isMobile } from 'react-device-detect';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Callcenterimg from '../../img/Photo by Venezuela Responsable on May 26, 2023. May be an image of 1 person, bureau, computer keyboard, office and text..jpg';

export const WhyChooseUs = ({ offset }) => {
  const nowG = isMobile ? (offset > 2600 ? 60 : 0) : offset > 1800 ? 60 : 0;
  const nowC = isMobile ? (offset > 2600 ? 50 : 0) : offset > 1800 ? 50 : 0;
  const nowR = isMobile ? (offset > 2600 ? 85 : 0) : offset > 1800 ? 85 : 0;

  return (
    <div className="py-2 services_mother">
      <div className="container-xxl py-5" style={{ marginTop: '100px' }}>
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="h-100">
                <h6
                  style={{ backgroundColor: '#e8e8e6' }}
                  className="section-title text-start text-primary pe-2"
                >
                  ¿POR QUÉ ELEGIRNOS?
                </h6>
                <h1
                  style={{ color: '#072146' }}
                  className="display-6 mb-4 fw-bold services_mother__title"
                >
                  ¿Por qué la gente confía en nosotros? ¡Aprenda sobre nosotros!
                </h1>
                <p className="mb-4" style={{ fontSize: '1.1em' }}>
                  Responsabilidad, calidad de servicio, iniciativa, compromiso,
                  honestidad, trabajo en equipo y solidaridad
                </p>
                <div className="row g-4">
                  <div className="col-12">
                    <div className="skill">
                      <div className="d-flex justify-content-between">
                        <p className="mb-2">Renovaciones</p>
                      </div>
                      <div>
                        <ProgressBar
                          now={nowR}
                          label={`${nowR}%`}
                          style={{ height: '20px' }}
                          animated
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="skill">
                      <div className="d-flex justify-content-between">
                        <p className="mb-2">Incrementos de cobertura</p>
                      </div>
                      <div>
                        <ProgressBar
                          now={nowC}
                          label={`${nowC}%`}
                          style={{ height: '20px' }}
                          animated
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="skill">
                      <div className="d-flex justify-content-between">
                        <p className="mb-2">Servicios de grúa</p>
                      </div>
                      <div>
                        <ProgressBar
                          now={nowG}
                          label={`${nowG}%`}
                          style={{ height: '20px' }}
                          animated
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="img-border imageborder">
                <div className="img-border imageborder2">
                  <div className="img-border imageborder3"></div>
                </div>
                <img
                  className="img-fluid"
                  src={Callcenterimg}
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
