import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { LoginContext } from '../../context/LoginContext';

const GuardaReferido = () => {
  const { setCodigoDescuentoMostrar } = useContext(LoginContext);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const vagen = params.get('vagen');

    if (vagen) {
      const data = {
        codigoPresente: true,
        codigoDescuento: vagen,
      };

      localStorage.setItem(
        'RCV_DIGITAL_CODIGO_DESCUENTO',
        JSON.stringify(data)
      );
      setCodigoDescuentoMostrar(true);
    } else {
      localStorage.removeItem('RCV_DIGITAL_CODIGO_DESCUENTO');
    }
  }, [location.search]);

  return null;
};

export default GuardaReferido;
