import { Container, Row } from 'react-bootstrap';
import { Service } from './Service';
import servicesSeeder from './servicesSeeder/servicesseeder';

export const Services = () => {
  return (
    <div className="py-2 services_father">
      <Container fluid="xxl" className="py-2 services_father">
        <Container>
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: '600px' }}
          >
            <h6
              style={{ backgroundColor: '#e8e8e6' }}
              className="section-title text-center text-primary px-2"
            >
              Servicios
            </h6>
            <h1 className="display-6 mb-4 fw-bold" style={{ color: '#072146' }}>
              Nos enfocamos en ofrecer lo mejor en todos nuestros servicios
            </h1>
          </div>
          <Row className="g-4 ">
            {servicesSeeder.map(x => (
              <Service
                title={x.title}
                key={x.id}
                description={x.description}
                img={x.img}
                imagen={x.imagen}
              />
            ))}
          </Row>
        </Container>
      </Container>
    </div>
  );
};
