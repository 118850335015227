import { Text, View, StyleSheet } from "@react-pdf/renderer";
const ContratoLinea= () => {
    const styles = StyleSheet.create({
        container: {
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            marginTop: "10px",
            borderTop: "1px solid black",
            width: "540px",
        }
       
      });
    return(
    <View style={styles.container} >
      <Text></Text>
    </View>
    )
};

export default ContratoLinea;