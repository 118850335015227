import { useState, useEffect, useContext } from 'react';
import useProcesoProvider from '../../../hooks/useProcesoProvider';
import { Link, useNavigate } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import consultarApi from '../../../helpers/consultarApi';
import { TokenContext } from '../../../context/TokenContext';
import PlanEsqueleto from '../Esqueletos/PlanEsqueleto';
import useConteo from '../../../hooks/useConteo';
const Planes = () => {
  /* const { cambiarPaso } = useConteo();*/
  const context = useProcesoProvider();
  const { cambiarPaso } = context;
  const navigate = useNavigate();
  const [paquetes, setPaquetes] = useState([]);
  const [cargandoPlanes, setCargandoPlanes] = useState(true);
  const { setTokenCambiar, setTokenVencido, token, setToken, cancelar } =
    useContext(TokenContext);
  const clase = JSON.parse(localStorage.getItem('RCV_DIGITAL_CLASE'));
  localStorage.removeItem('RCV_DIGITAL_S/P');
  const handleSubmit = (e, x) => {
    e.preventDefault();
    const {
      id_pq,
      cd_pq,
      ds_pq,
      es_rg,
      id_pr,
      id_cl_vh,
      in_dd,
      in_ht,
      mt_pr,
      cd_cl_vh,
      ds_cl_vh,
      tp_cl_vh,
      mt_pq_cb_ba,
      ta_fa,
      im_pq,
    } = x;

    const planSeleccionado = {
      id_pq,
      cd_pq,
      ds_pq,
      es_rg,
      id_pr,
      id_cl_vh,
      in_dd,
      in_ht,
      mt_pr,
      cd_cl_vh,
      ds_cl_vh,
      tp_cl_vh,
      mt_pq_cb_ba,
      ta_fa,
      im_pq,
    };

    setTimeout(() => {
      localStorage.setItem(
        'RCV_DIGITAL_PLAN',
        JSON.stringify(planSeleccionado)
      );
      navigate('/proceso/cliente');
    }, 300);
  };

  useEffect(() => {
    cambiarPaso(1, 0);
  }, []);

  let urlPlanes = `v1/getPackage?p1=${clase.id_cl_vh}&p2=&p3=&p4=&op=2&token=`;

  useEffect(() => {
    setCargandoPlanes(true);
    consultarApi(
      urlPlanes,
      0,
      setPaquetes,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    ).finally(() => setCargandoPlanes(false));
  }, [token]);

  return (
    <div className="cartasContainer">
      <div className="cartasContainer__header">
        <div className="cartasContainer__header-titulo">
          <h2>
            Elige el mejor
            <span> PLAN</span> para tu <span>{clase?.ds_cl_vh}</span>.
          </h2>
        </div>
        <Link to={'/proceso/clases'}>
          <BsArrowLeft />
          Ir a clases
        </Link>
      </div>
      <div className="cartas">
        {cargandoPlanes ? (
          <PlanEsqueleto />
        ) : (
          paquetes?.map((x, indice) => {
            const {
              ds_pq,
              mt_pr,
              detalle,
              in_dd,
              in_ht,
              tp_cl_vh,
              mt_bs,
              in_re_pq,
            } = x;

            const dsPqTitle = ds_pq
              .replace('PLAN', '')
              .replace('MOTOS', '')
              .replace('MOTO', '');

            return (
              <div
                key={indice}
                className="cartas__item"
                onClick={e => handleSubmit(e, x)}
              >
                <div className="cartas__item-header">
                  <div className="cartas__item-header-title">
                    <p className="cartas__item-header-title-pqtitle">
                      {dsPqTitle}
                    </p>

                    <p className="cartas__item-header-title-pqsbtitle">
                      (Desde {in_dd}{' '}
                      {in_ht >= 100000
                        ? `${
                            tp_cl_vh === 'P'
                              ? `puesto${in_dd === 1 ? '' : 's'}`
                              : `tonelada${in_dd === 1 ? '' : 's'}`
                          } en adelante`
                        : `hasta ${in_ht} ${
                            tp_cl_vh === 'P'
                              ? `puesto${in_dd === 1 ? '' : 's'}`
                              : `tonelada${in_dd === 1 ? '' : 's'}`
                          }`}
                      )
                    </p>
                  </div>

                  <div className="cartas__item-header-precio">
                    <span className="cartas__item-header-precio-monto">
                      <span className="cartas__item-header-precio-monto-ref">
                        REF
                      </span>
                      {mt_pr}
                    </span>
                    <span className="cartas__item-header-precio-montobs">
                      BS{' '}
                      {mt_bs.toLocaleString('es-VE', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                    {in_re_pq === 1 ? (
                      <div className="cartas__item-header-precio-recomendado">
                        RECOMENDADO
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="cartas__item-section">
                  <div className="cartas__item-section-caracteristicas">
                    <ul className="cartas__item-section-caracteristicas-lista">
                      {detalle?.map((y, indice) => {
                        return (
                          <li
                            key={indice}
                            className="cartas__item-section-caracteristicas-lista-item"
                          >
                            <img
                              src={y.im_pq_dt}
                              alt="icono de caracteristicas en los planes"
                            />{' '}
                            <span>{y.ds_pq_dt}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="cartas__item-footer">
                  <button>SELECCIONAR</button>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default Planes;
