import React from 'react';
import checkImg from '../../styles/images/check-mark-icon-free-png.png';

const ActivacionCorrecta = () => {
  return (
    <div className="activacionCorrecta">
      <div className="activacionCorrecta__container">
        <div className="activacionCorrecta__container-card">
          <img src={checkImg} alt="imagen de icono" />
          <p>Su cuenta ha sido activada exitosamente</p>
          <a href="https://rcvdigital.venezuelaresponsable.com.ve">
            Ir a RCV Digital
          </a>
        </div>
      </div>
    </div>
  );
};

export default ActivacionCorrecta;
