import React, { useState, useEffect, useContext } from 'react';
import ActivacionCorrecta from './ActivacionCorrecta';
import ActivacionIncorrecta from './ActivacionIncorrecta';
import axios from 'axios';
import { getToken } from '../../helpers/getToken';
import { LoginContext } from '../../context/LoginContext';
import { TokenContext } from '../../context/TokenContext';

const Activacion = async () => {
  const [responseData, setResponseData] = useState([]);
  const { setNavbar } = useContext(LoginContext);
  const [responseError, setResponseError] = useState({
    status: false,
    info: [],
  });

  let login = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));
  const { setTokenCambiar, setTokenVencido } = useContext(TokenContext);
  useEffect(() => {
    setNavbar(false);
  }, []);
  const token = await getToken();
  const aprobar = async () => {
    const searchParams = new URLSearchParams(window.location.search);

    const cd = searchParams.get('cd');
    const c = searchParams.get('c');
    const l = searchParams.get('l');
    let url = `https://apicli.venezuelaresponsable.com.ve/AppVr.svc/v1/activateUser?p1=${cd}&p2=${c}&p3=${l}&p4=&op=1&token=${token}`;
    try {
      axios
        .get(url)
        .then(response => {
          setResponseData(response.data.data);
          localStorage.setItem(
            'RCV_DIGITAL_TOKEN',
            JSON.stringify(response.data.token)
          );
          setResponseError({ status: false, info: [] });
        })
        .catch(async error => {
          if (error.response.status === 500) {
            setResponseError({
              status: true,
              info: error.response.data.message,
            });
          } else {
            setResponseError({ status: false, info: [] });
          }
          if (error.response && error.response.status === 403) {
            if (login === undefined || login === null) {
              const tokenAct = await getToken();
              localStorage.setItem(
                'RCV_DIGITAL_TOKEN',
                JSON.stringify(tokenAct)
              );

              let urlNueva = `https://apicli.venezuelaresponsable.com.ve/AppVr.svc/v1/activateUser?p1=${cd}&p2=${c}&p3=${l}&p4=&op=1&token=${tokenAct}`;
              try {
                axios.get(urlNueva).then(response => {
                  setResponseData(response.data.data);
                  localStorage.setItem(
                    'RCV_DIGITAL_TOKEN',
                    JSON.stringify(response.data.token)
                  );
                  setResponseError({ status: false, info: [] });
                });
              } catch (error) {
                if (error.response.status === 500) {
                  setResponseError({
                    status: true,
                    info: error.response.data.message,
                  });
                } else {
                  setResponseError({ status: false, info: [] });
                }
              }
            } else {
              setTokenCambiar(true);
              setTokenVencido(error.response.data.token);
              console.log(error.response.data.token);
            }
          }
        });
    } catch (error) {
      console.log(error);
      console.error('Error al realizar la solicitud a la API:', error);
      setResponseError({ status: true, info: error });
    }
  };

  useEffect(() => {
    aprobar();
  }, [token]);

  return (
    <div className="activacion">
      {responseError.status === false ? (
        <ActivacionCorrecta />
      ) : (
        <ActivacionIncorrecta />
      )}
    </div>
  );
};

export default Activacion;
