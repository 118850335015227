import { useState } from 'react';
import Pagination from '../../Pagination/Pagination';
import { useEffect } from 'react';
import AdminCartas from './AdminCartas';
import AdminTabla from './AdminTabla';

const TableAdministration = ({ data, validar, rechazar, valorBusqueda }) => {
  const [contador, setContador] = useState(0);
  const [datos, setDatos] = useState([]);
  const [page, setPage] = useState();
  const [isMobile, setIsMobile] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (searchResults?.length === 0) {
      const grupos = data?.reduce((acc, _, index) => {
        if (index % 10 === 0) {
          acc.push(data?.slice(index, index + 10));
        }
        return acc;
      }, []);
      setDatos(grupos);
      setPage(data?.length - 1);
    }

    if (searchResults?.length != 0) {
      const grupos = searchResults?.reduce((acc, _, index) => {
        if (index % 10 === 0) {
          acc.push(searchResults?.slice(index, index + 10));
        }
        return acc;
      }, []);
      setDatos(grupos);
      setPage(searchResults?.length - 1);
    }
  }, [data, searchResults]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const results = data?.filter(
      item =>
        item.NOMBRE.toLowerCase().includes(valorBusqueda.toLowerCase()) ||
        item.TFCL01.includes(valorBusqueda) ||
        item.cd_cl.toLowerCase().includes(valorBusqueda.toLowerCase()) ||
        item.cd_co.toLowerCase().includes(valorBusqueda.toLowerCase()) ||
        item.cl_vh.toLowerCase().includes(valorBusqueda.toLowerCase()) ||
        item.ds_bc.toLowerCase().includes(valorBusqueda.toLowerCase()) ||
        item.ds_cl_vh.toLowerCase().includes(valorBusqueda.toLowerCase()) ||
        item.ds_mc.toLowerCase().includes(valorBusqueda.toLowerCase()) ||
        item.ds_md.toLowerCase().includes(valorBusqueda.toLowerCase()) ||
        item.ds_pq.toLowerCase().includes(valorBusqueda.toLowerCase()) ||
        item.ds_tp_pg.toLowerCase().includes(valorBusqueda.toLowerCase()) ||
        item.dt_tp_pg.includes(valorBusqueda) ||
        item.id_co.toLowerCase().includes(valorBusqueda.toLowerCase()) ||
        item.id_fc.toLowerCase().includes(valorBusqueda.toLowerCase()) ||
        item.pl_vh.toLowerCase().includes(valorBusqueda.toLowerCase())
    );
    setSearchResults(results);
  }, [data, valorBusqueda]);

  return (
    <div className="adminDashboard">
      <div className="adminDashboard__body">
        <AdminTabla
          datos={datos}
          rechazar={rechazar}
          validar={validar}
          contador={contador}
        />

        {datos &&
          datos[contador]?.map((x, indice) => {
            let plan = x.ds_pq.split('-')[1];
            return (
              <AdminCartas
                key={indice}
                indice={indice}
                plan={plan}
                rechazar={rechazar}
                x={x}
                validar={validar}
              />
            );
          })}
      </div>
      <Pagination
        page={page}
        contador={contador}
        setContador={setContador}
        datos={datos}
      />
    </div>
  );
};

export default TableAdministration;
