import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
const styles = StyleSheet.create({
  tableRowTotales: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
  },
  tableColTotales: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    minHeight: '12px',
    borderRightWidth: 1,
    textAlign: 'center',
    fontSize: 6,
    padding: 2,
  },
  tableColContraTotales: {
    fontFamily: 'Arial',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    minHeight: '12px',
    borderRightWidth: 1,
    textAlign: 'start',
    fontSize: 7,
    padding: 2,
    fontWeight: 'bold',
    fontStyle: 'bold',
  },
  tableColMontoTotales: {
    fontFamily: 'Arial',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    minHeight: '12px',
    borderRightWidth: 1,
    textAlign: 'right',
    fontSize: 6,
    padding: 2,
    fontWeight: 'bold',
    fontStyle: 'bold',
  },
});

const TableTotalesComisiones = ({ reporte }) => {
  let tasa = reporte?.VentasCommission[0].tasa;
  const sumarMtBaTt = campo => {
    const ventasDiarias = reporte?.VentasCommission;
    let sumaMtBaTt = 0;

    ventasDiarias?.forEach(venta => {
      sumaMtBaTt += venta[campo];
    });

    if (sumaMtBaTt === 0) {
      return '0, 00';
    } else {
      return sumaMtBaTt.toLocaleString('es-VE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  };

  const sumarRef = () => {
    const ventasDiarias = reporte?.VentasCommission;
    let sumaMtBaTt = 0;

    ventasDiarias?.forEach(venta => {
      sumaMtBaTt += venta.mt_ge_tt;
    });

    return sumaMtBaTt / Number(tasa);
  };
  return (
    <View style={styles.tableRowTotales}>
      <Text style={[styles.tableColContraTotales, { width: '38.8%' }]}>
        Totales:
      </Text>
      <Text style={[styles.tableColMontoTotales, { width: '5.4%' }]}>
        {sumarMtBaTt('mt_ex_tt')}
      </Text>
      <Text style={[styles.tableColMontoTotales, { width: '5.4%' }]}>
        {sumarMtBaTt('mt_ba_tt')}
      </Text>
      <Text style={[styles.tableColMontoTotales, { width: '5.4%' }]}>
        {sumarMtBaTt('mt_iv_tt')}
      </Text>
      <Text style={[styles.tableColMontoTotales, { width: '5.4%' }]}>
        {sumarMtBaTt('mt_igtf')}
      </Text>
      <Text style={[styles.tableColTotales, { width: '5.4%' }]}></Text>
      <Text style={[styles.tableColMontoTotales, { width: '5.4%' }]}>
        {sumarMtBaTt('mt_ge_tt')}
      </Text>
      <Text style={[styles.tableColTotales, { width: '5.4%' }]}></Text>
      <Text style={[styles.tableColTotales, { width: '5.4%' }]}></Text>

      <Text style={[styles.tableColMontoTotales, { width: '5.4%' }]}></Text>
      <Text style={[styles.tableColMontoTotales, { width: '5.4%' }]}>
        {sumarRef().toFixed(2)}
      </Text>
      <Text style={[styles.tableColMontoTotales, { width: '5.4%' }]}></Text>
      <Text style={[styles.tableColTotales, { width: '5.4%' }]}></Text>
    </View>
  );
};

export default TableTotalesComisiones;
