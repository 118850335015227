import Service_1 from '../../../img/service-1.png';
import Service_2 from '../../../img/service-2.png';
import Service_3 from '../../../img/service-3.png';
import Service_4 from '../../../img/promodelmes.jpeg';
import Service_5 from '../../../img/service-5.png';
import Service_6 from '../../../img/service-6.png';

const servicesSeeder = [
  {
    id: 1,
    title: 'Responsabilidad civil',
    description:
      'Te ofrecemos las mejores coberturas a precios sorprendentes para tu tranquilidad al momento de cubrir un accidente.',
    img: Service_1,
    imagen: false,
  },
  {
    id: 2,
    title: 'Asistencia y traslado al contratante',
    description:
      'Con nuestros excelentes paquetes de asistencia de traslado al contratante no te quedes varado, inclúyelos en tu RCV a un precio accesible.',
    img: Service_2,
    imagen: false,
  },
  {
    id: 3,
    title: 'Excesos de coberturas',
    description:
      'Échale un ojo a nuestros exceso de cobertura y AUMENTA tu límite de póliza.',
    img: Service_3,
    imagen: false,
  },
  {
    id: 4,
    title: 'Promo del mes',
    description:
      'Dale tranquilidad a tu vida en un momento tan difícil, asegura la manera de agilizar tus trámites al momento de un servicio funerario.',
    img: Service_4,
    imagen: true,
  },
  {
    id: 6,
    title: 'Servicio de grúas',
    description:
      'Aprovecha nuestros servicios de grúas y aprovecha el amplio paquete de servicios para tu tranquilidad al momento de quedarte varado.',
    img: Service_6,
    imagen: false,
  },
];

export default servicesSeeder;
