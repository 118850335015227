import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import RCVLogo from "../../../styles/images/f7c38f45-fb8c-44ea-8d00-62d7d2427a4d (1).jpg";

const styles = StyleSheet.create({
  container: {
    width: "540px",
    marginTop: "20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "20px",
  },
  containerView: {
    maxWidth: "340px",
  },
  image: {
    width: "150px",
    height: "80px",
    justifySelf: "start",
  },
  font: {
    fontSize: "8px",
  },
});

const ContratoLogo = () => (
  <div style={styles.container}>
    <Image style={styles.image} src={RCVLogo} />
    <View style={styles.containerView}>
      <Text style={styles.font}>
        INSCRITA EN EL REGISTRO MERCANTIL CUARTO DE LA CIRCUNSCRIPCIÓN JUDICIAL
        DE MARACAIBO EDO-ZULIA REGISTRADO BAJO EL Nº. 36 TOMO 59-A
      </Text>
    </View>
  </div>
);

export default ContratoLogo;
