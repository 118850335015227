import React, { useState, useEffect, useContext } from 'react';
import HeaderPages from '../HeadersPages/HeaderPages';
import NavbarErrors from '../NavbarLite/NavbarErrors';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFDocument from './PDFDocument';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import consultarApi from '../../helpers/consultarApi';
import { TokenContext } from '../../context/TokenContext';

const VentasDiarias = () => {
  const [fecha, setFecha] = useState('');
  const [hora, setHora] = useState('');
  const [currentDate] = useState(new Date());
  const [userProfile] = useState(
    JSON.parse(localStorage.getItem('RCV_DIGITAL_PROFILEOBJ'))
  );

  const [fechaIni, setFechaIni] = useState();
  const [fechaFin, setFechaFin] = useState();
  const [navbarErrores, setNavbarErrores] = useState([]);
  const [mostrarErrores, setMostrarErrores] = useState(false);
  const [reporteDescarga, setReporteDescarga] = useState();
  const [reporte, setReporte] = useState();
  const [cargando, setCargando] = useState(false);

  const { setTokenCambiar, setTokenVencido, setToken, cancelar } =
    useContext(TokenContext);
  const cambiarFecha = (e, tipo) => {
    if (tipo === 'ini') {
      setFechaIni(e.target.value);
    } else {
      setFechaFin(e.target.value);
    }
  };

  function getPrimerDiaDelMes() {
    const hoy = new Date();
    setFechaIni(
      new Date(hoy.getFullYear(), hoy.getMonth(), 1).toISOString().substr(0, 10)
    );
  }

  function getUltimoDiaDelMes() {
    const hoy = new Date();
    setFechaFin(
      new Date(hoy.getFullYear(), hoy.getMonth() + 1, 0)
        .toISOString()
        .substr(0, 10)
    );
  }

  useEffect(() => {
    getPrimerDiaDelMes();
    getUltimoDiaDelMes();
  }, []);

  const buscar = () => {
    setCargando(true);
    if (!fechaIni || !fechaFin) {
      setCargando(false);
      setNavbarErrores(['Debes colocar las fechas']);
      setMostrarErrores(true);
    } else {
      let urlReportes = `v1/getDaySales?p1=&p2=&p3=&p4=&dd=${fechaIni}&ht=${fechaFin}&op=6&token=`;
      consultarApi(
        urlReportes,
        0,
        setReporte,
        setTokenCambiar,
        setTokenVencido,
        setToken,
        cancelar
      ).finally(
        setTimeout(() => {
          setCargando(false);
        }, 2000)
      );
    }
  };

  const renderizar = () => {
    const fechaFormateada = format(currentDate, 'dd/MM/yyyy', { locale: es });
    const horaFormateada = format(currentDate, 'hh:mm:ss a', { locale: es });
    setFecha(fechaFormateada);
    setHora(horaFormateada);
    let urlReportesDescarga = `v1/getDaySales?p1=&p2=&p3=&p4=&dd=${fechaIni}&ht=${fechaFin}&op=6&token=`;
    consultarApi(
      urlReportesDescarga,
      0,
      setReporteDescarga,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    );

    setTimeout(() => {
      setTimeout(() => {
        document.getElementById('aqui').click();
      }, 1000);
    }, 3000);
  };

  useEffect(() => {
    if (reporte != undefined && reporte != null && reporte != '') {
      setTimeout(() => {
        window.open(
          `/ventasdiarias/resultado/${fechaIni}/${fechaFin}`,
          '_blank'
        );
      }, 1000);
    }
    if (reporte === null && cargando === true) {
      setCargando(false);
      setReporte('');
      setNavbarErrores(['No hay registros en las fechas seleccionadas']);
      setMostrarErrores(true);
    }
  }, [reporte]);

  return (
    <div className="reportes">
      <NavbarErrors
        navbarErrores={navbarErrores}
        setMostrarErrores={setMostrarErrores}
        mostrarErrores={mostrarErrores}
      />
      <div className="reportes__container">
        <HeaderPages
          titulo={'Reportes'}
          info={'Elige el rango de fecha y obtén tu reporte de ventas'}
        />
        <div className="reportes__container-seleccion">
          <div>
            <input
              className="reportes__container-seleccion-date"
              type="date"
              onChange={e => cambiarFecha(e, 'ini')}
              value={fechaIni}
            />
          </div>
          <div>
            <input
              className="reportes__container-seleccion-date"
              type="date"
              onChange={e => cambiarFecha(e, 'fin')}
              value={fechaFin}
            />
          </div>
          <button
            className="botonpc"
            onClick={buscar}
            disabled={cargando === true ? true : false}
          >
            {cargando === false ? (
              'Aceptar'
            ) : (
              <div className="lds-ellipsisg">
                <div style={{ backgroundColor: 'white' }}></div>
                <div style={{ backgroundColor: 'white' }}></div>
                <div style={{ backgroundColor: 'white' }}></div>
                <div style={{ backgroundColor: 'white' }}></div>
              </div>
            )}
          </button>
          <button className="botonmobile" onClick={() => renderizar()}>
            Aceptar
          </button>
        </div>
      </div>
      <PDFDownloadLink
        document={
          <PDFDocument
            reporte={reporteDescarga}
            userProfile={userProfile}
            fecha={fecha}
            hora={hora}
            fechaini={fechaIni}
            fechafin={fechaFin}
          />
        }
        fileName={`REPORTE_VENTAS_DIARIAS.pdf`}
      >
        <button id="aqui" style={{ display: 'none' }}>
          aqui
        </button>
      </PDFDownloadLink>
    </div>
  );
};

export default VentasDiarias;
