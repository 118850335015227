import React, { useState, useEffect } from 'react';
import ImgPromo1 from '../../styles/images/WhatsApp Image 2023-08-25 at 10.00.10 AM.jpeg';
import ImgPromo2 from '../../styles/images/WhatsApp Image 2024-08-05 at 09.27.17.jpeg';
import { AiOutlineClose } from 'react-icons/ai';

const imagenes = [
  {
    url: ImgPromo1,
    alt: 'imagen de promocion VR',
  },
  {
    url: ImgPromo2,
    alt: 'imagen de promocion VR',
  },
];

const BannerImage = () => {
  const [show, setShow] = useState();
  const [count, setCount] = useState(
    parseInt(localStorage.getItem('bannerImageCount')) || 0
  );

  useEffect(() => {
    setShow(true);
  }, []);
  const toggleBanner = () => {
    if (show) {
      setShow(false);
      const newCount = count === 0 ? 1 : 0;
      setCount(newCount);
      localStorage.setItem('bannerImageCount', newCount);
    } else {
      setShow(true);
    }
  };

  const eliminarBanner = e => {
    e.preventDefault();
    localStorage.removeItem('bannerImageCount');
    localStorage.setItem('nobanner', true);
    localStorage.removeItem('sibanner');
    setShow(false);
  };
  return (
    <div className={show ? 'bannerImage' : 'bannerImage active'}>
      <div className="bannerImage__background" onClick={toggleBanner}></div>
      <div className="bannerImage__container">
        <button
          className="bannerImage__container-eliminador"
          onClick={eliminarBanner}
        >
          No mostrar mas
        </button>
        <AiOutlineClose
          className="bannerImage__container-closer"
          onClick={toggleBanner}
        />

        <img
          className="bannerImage__container-img"
          src={imagenes[count].url}
          alt={imagenes[count].alt}
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default BannerImage;
