import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    borderTop: '1px solid black',
    width: '540px',
    padding: '2px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start',
    textAlign: 'start',
  },
  containerCondiciones: {
    border: '1px solid black',
    width: '100%',
    padding: '2px',
    textAlign: 'center',
  },
  font: {
    fontSize: 6,
    textAlign: 'start',
    fontWeight: 700,
  },
  fontTermino: {
    fontSize: 6,
    textAlign: 'center',
    fontWeight: 700,
  },
  fontMini: {
    fontSize: 5,
    textAlign: 'center',
  },
});
const ContratoAsis = ({ contratoData }) => (
  <div style={styles.container}>
    <View>
      <Text style={styles.font}> {contratoData.ds_co_gr}</Text>
    </View>
    <div style={styles.containerCondiciones}>
      <View>
        <Text style={styles.fontTermino}>CONDICIONES DEL SERVICIO DE GRÚA</Text>
      </View>
      <View>
        <Text style={styles.fontMini}>
          1)UD., PODRÁ SOLICITAR NUESTROS SERVICIOS MEDIANTE NUESTRO CALL-CENTER
          POR LOS TELÉFONOS LUN-VIE 8AM-5PM (0412)688.16.64 (0424)624.30.38
          HORARIO NOCTURNO, FINES DE SEMANA Y FERIADOS (0424)638.27.63 2) EL
          SERVICIO ESTÁ DISPONIBLE LAS 24 HORAS DEL DÍA LOS 365 DÍAS DEL AÑO. 3)
          BRINDAMOS SERVICIO DE GRÚAS URBANO HASTA 25 KM A NIVEL NACIONAL (PARA
          LLEVAR EL VEHÍCULO A PUNTO DE SOLUCIÓN, TALLER Y/O RESGUARDO
          RESIDENCIAL) SON SERVICIOS URBANOS LOCALES, DENTRO DE LOS LÍMITES
          INTERNOS DE CADA CIUDAD HASTA UN MÁXIMO DE 25 KM, DESDE EL PUNTO DE
          ORIGEN HASTA SU DESTINO. *NACIONAL SOLO PARA LOS PLANES DE 50$ Y 100$
          4) LOS SERVICIOS ADQUIRIDOS MEDIANTE LOS PLANES SÓLO SE PRESTARÁN SI
          HA CUMPLIDO CON LAS SIGUIENTES CONDICIONES: LOS SERVICIOS POR FALLAS
          MECÁNICAS Y/O ELÉCTRICAS SE ACTIVARÁN EN UN PLAZO DE 15 DÍAS
          CONTINUOS, LUEGO DEL PAGO TOTAL DEL PRESENTE CONTRATO (ES DECIR SE
          ACTIVA EL DÍA 16 DESPUÉS DE CONTRATADO EL SERVICIO) Y EN CASO DE
          COLISIÓN Y/O CHOQUE SE ACTIVARÁ EN UN PLAZO DE 48 HORAS DESPUÉS DE
          CONTRATADO EL SERVICIO CUANDO A CONSECUENCIA DEL MISMO IMPIDA LA
          CIRCULACIÓN DEL VEHÍCULO.
        </Text>
      </View>
    </div>
  </div>
);

export default ContratoAsis;
