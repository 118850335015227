import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    right: "150px",
    top: "85px",
  },
  font: {
    fontSize: "8px",
    fontFamily: "Arial",
    fontStyle: "bold",
  },
});
const ContratoVigencia = () => (
  <View style={styles.container}>
    <Text style={styles.font}>VIGENCIA ANUAL:</Text>
  </View>
);
export default ContratoVigencia;
