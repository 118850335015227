import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import PDFDocument from '../../PDFViewer/PDFDocument';

const PDFContratoRenderizador = ({
  contratoData,
  tiempo,
  planA,
  planB,
  planC,
  sumaA,
  sumaB,
  sumaC,
  datosA,
  datosB,
  datosC,
  sumaTotal,
  imagenqr,
  imgContratoQR,
}) => {
  return (
    <PDFViewer width="100%" height="1000">
      <PDFDocument
        contratoData={contratoData}
        fecha={tiempo.fecha}
        hora={tiempo.hora}
        planA={planA}
        planB={planB}
        planC={planC}
        sumaA={sumaA}
        sumaB={sumaB}
        sumaC={sumaC}
        datosA={datosA}
        datosB={datosB}
        datosC={datosC}
        sumaTotal={sumaTotal}
        imagenqr={imagenqr}
        imgContratoQR={imgContratoQR}
      />
    </PDFViewer>
  );
};

export default PDFContratoRenderizador;
