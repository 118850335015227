import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    fontFamily: 'Arial',
    width: '540px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: '2px',
    marginTop: '5px',
  },
  containerTotal: {
    alignSelf: 'flex-end',
    right: '0',
  },
  font: {
    fontSize: 9,
    fontStyle: 'bold',
  },
  fontFirma: {
    fontSize: 7,
    fontStyle: 'bold',
  },

  firmaContainer: {
    borderTop: '1px solid black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '160px',
    marginTop: '15px',
  },
});

const ContratoBene = ({ servicioGrua, sumaTotal, contratoData }) => {
  function formatearMonto(monto) {
    const partes = monto?.toFixed(2).split('.');
    const entero = partes && partes[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return `${entero},${partes && partes[1]}`;
  }

  const montoFormateado = formatearMonto(contratoData?.mt_pg_co);
  const montoBeneFormateado = formatearMonto(sumaTotal);
  return (
    <div style={styles.container}>
      {servicioGrua === 1 ? (
        <View style={styles.firmaContainer}>
          <Text style={styles.fontFirma}>ACEPTO</Text>
        </View>
      ) : (
        <View>
          <Text></Text>
        </View>
      )}
      <div style={styles.containerTotal}>
        <View>
          <Text style={styles.font}>
            TOTAL BENEFICIOS SUMADOS BS.: {montoBeneFormateado}
          </Text>
        </View>
        <View>
          <Text style={styles.font}>TOTAL PAGADO BS.: {montoFormateado}</Text>
        </View>
      </div>
    </div>
  );
};
export default ContratoBene;
