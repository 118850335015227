import { useEffect, useState, useContext } from 'react';
import ModalCorrecto from '../components/ModalCorrecto/ModalCorrecto';
import consultarApi from '../helpers/consultarApi';
import { TokenContext } from '../context/TokenContext';
import DashboardTabla from '../components/Dashboard/DashboardTabla/DashboardTabla';
import DashboardInfo from '../components/DashboardInfo/DashboardInfo';

const Dashboard = () => {
  const [vehiContratos, setVehiContratos] = useState([]);
  let pago = JSON.parse(localStorage.getItem('RCV_DIGITAL_PAGO'));
  const [cargandoContratos, setCargandoContratos] = useState(true);
  const { setTokenCambiar, setTokenVencido, token, setToken, cancelar } =
    useContext(TokenContext);
  const [mensajeCorrecto, setMensajeCorrecto] = useState('');
  const [mostrarModalCorrecto, setMostrarModalCorrecto] = useState(false);

  useEffect(() => {
    consultarApi(
      process.env.REACT_APP_HISTORIAL,
      0,
      setVehiContratos,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    ).finally(() => setCargandoContratos(false));
  }, [token]);

  useEffect(() => {
    localStorage.removeItem('RCV_DIGITAL_PLAN_AN');
    localStorage.removeItem('LAST_LOCATION');
    localStorage.removeItem('PROPIETARIO_ID');
    localStorage.removeItem('CLIENT_ID');
    localStorage.removeItem('RCV_DIGITAL_CLIENTE');
    localStorage.removeItem('RCV_DIGITAL_PROPIETARIO');
    localStorage.removeItem('RCV_DIGITAL_VEHICULO');
    localStorage.removeItem('VEHICULO_ID');
    localStorage.removeItem('RCV_DIGITAL_CARRITO');
    localStorage.removeItem('RCV_DIGITAL_DESCUENTO');
    localStorage.removeItem('TOTAL_PAGO');
    localStorage.removeItem('RCV_DIGITAL_PASO_VERIFICADO');
    localStorage.removeItem('RCV_DIGITAL_S/P');
    localStorage.removeItem('RCV_DIGITAL_DESCONTADO');
    localStorage.removeItem('RCV_DIGITAL_USDESCUENTO');
  }, []);
  useEffect(() => {
    if (pago === true) {
      setMensajeCorrecto(
        'Excelente!, su pago está siendo procesado, al ser aprobado podrás descargar el contrato y la factura.'
      );
      setMostrarModalCorrecto(true);
      localStorage.removeItem('RCV_DIGITAL_PAGO');
    }
  }, []);

  return (
    <div className="newDashboard">
      <ModalCorrecto
        mostrarModalCorrecto={mostrarModalCorrecto}
        setMostrarModalCorrecto={setMostrarModalCorrecto}
        mensajeCorrecto={mensajeCorrecto}
      />
      <DashboardTabla
        vehiContratos={vehiContratos}
        cargandoContratos={cargandoContratos}
      />
    </div>
  );
};

export default Dashboard;
