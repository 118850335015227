import axios from '../api/axios';

const postTokenNuevo = async (setToken, tokenVencido, setTokenCambiar) => {
  let json = JSON.stringify({
    trace: '0',
    token: tokenVencido,
  });

  try {
    const data = await axios.post(process.env.REACT_APP_ACTUALIZACION, json);
    setTokenCambiar(false);
    localStorage.setItem('RCV_DIGITAL_TOKEN', JSON.stringify(data.data.token));
    setToken(data.data.token);
  } catch (error) {
    console.log(error);
  }
};

export default postTokenNuevo;
