import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    fontFamily: 'Arial',
    width: '540px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    gap: '4px',
  },
  font: {
    fontSize: '8px',
  },

  fontBold: {
    fontStyle: 'bold',
    fontSize: '8px',
  },
  viewerContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  extraContainer: {
    position: 'absolute',
    top: '70px',
    left: '200px',
  },
});

const ContratoInfo = ({ contratoData }) => {
  return (
    <div style={styles.container}>
      <View style={styles.viewerContainer}>
        <Text style={styles.fontBold}>OFICINA: </Text>
        <Text style={styles.font}>OFICINA VIRTUAL</Text>
      </View>
      <View style={styles.viewerContainer}>
        <Text style={styles.fontBold}>DIRECCION: </Text>
        <Text style={styles.font}>{contratoData && contratoData?.dr_of}</Text>
      </View>
      <View style={styles.viewerContainer}>
        <Text style={styles.fontBold}>TELEFONOS: </Text>
        <Text style={styles.font}>
          (+58) - 0412-1628233 / (+58) - 0261-7193619
        </Text>
      </View>
      <View style={styles.viewerContainer}>
        <Text style={styles.fontBold}>CONTRATANTE: </Text>
        <Text style={styles.font}>
          {contratoData && contratoData?.nombre + ' '}
          <Text style={styles.fontBold}>RIF: </Text>
          {contratoData && contratoData?.cd_cl}
        </Text>
      </View>
      <View style={styles.viewerContainer}>
        <Text style={styles.fontBold}>TELEFONOS: </Text>
        <Text style={styles.font}>
          {contratoData && contratoData?.tf_celular_01}
        </Text>
      </View>
      <View style={styles.viewerContainer}>
        <Text style={styles.fontBold}>CONTRATO: </Text>
        <Text style={styles.font}>{contratoData?.cd_co}</Text>
      </View>
      <View style={styles.extraContainer}>
        <Text style={styles.fontBold}>CARACTERISTICAS DEL VEHICULO</Text>
      </View>
    </div>
  );
};

export default ContratoInfo;
