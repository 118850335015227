import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
const styles = StyleSheet.create({
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
  },
  tableColHeader: {
    fontFamily: 'Arial',
    width: '100%',
    height: '100%',
    borderStyle: 'solid',
    borderRightWidth: 1,
    textAlign: 'center',
    fontWeight: 'bold',
    fontStyle: 'bold',
    fontSize: 7,
    padding: 4,
    paddingTop: '6px',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: ' center',
  },
  tableCol: {
    width: '100%',
    borderStyle: 'solid',
    borderRightWidth: 1,
    textAlign: 'start',
    fontSize: 7,
    padding: 5,
    fontFamily: 'Arial',
    fontWeight: 'bold',
    fontStyle: 'bold',
  },
  tableColCant: {
    width: '100%',
    borderStyle: 'solid',
    borderRightWidth: 1,
    textAlign: 'center',
    fontSize: 7,
    padding: 5,
  },
  tableColMontos: {
    width: '100%',
    borderStyle: 'solid',
    borderRightWidth: 1,
    textAlign: 'right',
    fontSize: 7,
    padding: 5,
    fontFamily: 'Arial',
    fontWeight: 'bold',
    fontStyle: 'bold',
  },
});

const ResumenDeVentasTotalesComision = ({ reporte }) => {
  let tasa = reporte?.VentasCommission[0]?.tasa;
  const sumarMtBaTt = campo => {
    const ventasDiarias = reporte?.ResumenVentasCommission;
    let sumaMtBaTt = 0;

    ventasDiarias?.forEach(venta => {
      sumaMtBaTt += venta[campo];
    });

    if (sumaMtBaTt === 0) {
      return '0, 00';
    } else {
      return sumaMtBaTt.toLocaleString('es-VE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  };

  const sumarRef = () => {
    const ventasDiarias = reporte?.ResumenVentasCommission;
    let sumaMtBaTt = 0;

    ventasDiarias?.forEach(venta => {
      sumaMtBaTt += venta.sm_mt_ge_tt;
    });

    return sumaMtBaTt / Number(tasa);
  };

  return (
    <View style={styles.tableRow}>
      <Text style={[styles.tableCol, { width: '30%' }]}>Totales:</Text>
      <Text style={[styles.tableColMontos, { width: '10%' }]}>
        {sumarMtBaTt('sm_mt_ex_tt')}
      </Text>
      <Text style={[styles.tableColMontos, { width: '10%' }]}>
        {sumarMtBaTt('sm_mt_ba_tt')}
      </Text>
      <Text style={[styles.tableColMontos, { width: '10%' }]}>
        {sumarMtBaTt('sm_mt_iv_tt')}
      </Text>
      <Text style={[styles.tableColMontos, { width: '10%' }]}>
        {sumarMtBaTt('sm_mt_igtf')}
      </Text>
      <Text style={[styles.tableColMontos, { width: '10%' }]}>
        {sumarMtBaTt('sm_mt_ds_tt')}
      </Text>
      <Text style={[styles.tableColMontos, { width: '10%' }]}>
        {sumarMtBaTt('sm_mt_ge_tt')}
      </Text>
      <Text style={[styles.tableColMontos, { width: '10%' }]}></Text>
      <Text style={[styles.tableColMontos, { width: '10%' }]}>
        {sumarMtBaTt('sm_mt_cm_ag')}
      </Text>

      <Text style={[styles.tableColMontos, { width: '10%' }]}>
        {sumarRef().toFixed(2)}
      </Text>
    </View>
  );
};

export default ResumenDeVentasTotalesComision;
