import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    fontFamily: 'Arial',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '540px',
  },

  containerFather: {
    display: 'flex',
    flexDirection: 'column',
  },

  containerSon: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    marginTop: '10px',
    borderTop: '1px solid black',
  },

  containerFooter: {
    fontFamily: 'Arial',
    width: '540px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },

  font: {
    fontSize: 7,
    marginLeft: '30px',
  },
  fontPrice: {
    fontSize: 7,
  },
  fontDif: {
    fontSize: 8,
  },

  fontBold3: {
    fontSize: 8,
    fontStyle: 'bold',
  },

  fontBold2: {
    fontSize: 7,
    fontStyle: 'bold',
  },
  fontBold: {
    fontSize: '8px',
    textDecoration: 'underline',
    fontStyle: 'bold',
  },
});

const ContratoPlanes = ({
  contratoData,
  planA,
  planB,
  planC,
  sumaA,
  sumaB,
  sumaC,
  datosA,
  datosB,
  datosC,
}) => {
  function formatearMonto(monto) {
    const partes = monto?.toFixed(2).split('.');
    const entero = partes && partes[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return `${entero},${partes && partes[1]}`;
  }

  const sumaAFor = formatearMonto(sumaA);
  const sumaBFor = formatearMonto(sumaB);
  const sumaCFor = formatearMonto(sumaC);

  return (
    <div style={styles.containerFather}>
      <div style={styles.container}>
        <View>
          <Text style={styles.fontDif}>
            {contratoData?.cd_pq} - {contratoData?.ds_pq}
          </Text>
        </View>
        <View>
          <Text style={styles.fontBold}>
            MONTOS REFLEJADOS Y PAGADOS TASA B.C.V
          </Text>
        </View>
      </div>

      {/* DIV CONTENEDOR DE PLANES*/}

      <div>
        <div style={styles.containerSon}>
          <div style={styles.container}>
            <View>
              <Text style={styles.fontBold2}>
                {datosA?.cod} - {datosA?.nombre}
              </Text>
            </View>
            <View>
              <Text style={styles.fontBold}></Text>
            </View>
          </div>
          {planA?.map((x, indice) => {
            return (
              <div style={styles.container} key={indice}>
                <View>
                  <Text style={styles.font}>{x?.ds_pq_dt}</Text>
                </View>
                <View>
                  <Text style={styles.fontPrice}>
                    {formatearMonto(x?.mt_pq_dt)}
                  </Text>
                </View>
              </div>
            );
          })}
          <div style={styles.containerFooter}>
            <View>
              <Text style={styles.fontBold3}>
                SUB TOTAL BENEFICIOS: {sumaAFor}
              </Text>
            </View>
          </div>
        </div>

        <div style={styles.containerSon}>
          <div style={styles.container}>
            <View>
              <Text style={styles.fontBold2}>
                {datosB?.cod} - {datosB?.nombre}
              </Text>
            </View>
            <View>
              <Text style={styles.fontBold}></Text>
            </View>
          </div>

          {planB?.map((x, indice) => {
            return (
              <div style={styles.container} key={indice}>
                <View>
                  <Text style={styles.font}>{x?.ds_pq_dt}</Text>
                </View>
                <View>
                  <Text style={styles.fontPrice}>
                    {formatearMonto(x?.mt_pq_dt)}
                  </Text>
                </View>
              </div>
            );
          })}
          <div style={styles.containerFooter}>
            <View>
              <Text style={styles.fontBold3}>
                SUB TOTAL BENEFICIOS: {sumaBFor}
              </Text>
            </View>
          </div>
        </div>
        <div style={styles.containerSon}>
          <div style={styles.container}>
            <View>
              <Text style={styles.fontBold2}>
                {datosC?.cod} - {datosC?.nombre}
              </Text>
            </View>
            <View>
              <Text style={styles.fontBold}></Text>
            </View>
          </div>
          {planC?.map((x, indice) => {
            return (
              <div style={styles.container} key={indice}>
                <View>
                  <Text style={styles.font}>{x?.ds_pq_dt}</Text>
                </View>
                <View>
                  <Text style={styles.fontPrice}>
                    {formatearMonto(x?.mt_pq_dt)}
                  </Text>
                </View>
              </div>
            );
          })}
          <div style={styles.containerFooter}>
            <View>
              <Text style={styles.fontBold3}>
                SUB TOTAL BENEFICIOS: {sumaCFor}
              </Text>
            </View>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContratoPlanes;
